import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import ArrowSteps from "../components/ArrowSteps";
import LandingIntro from "../components/LandingIntro";
import SingleOptions from "../components/SingleOptions";

import Spinner from "../images/spinner.png";
import { _onboarding_options } from "../modules/_onboarding_options";
import Fade from "react-reveal/Fade";
import { _auth } from "../modules/_auth";
import Skeleton from "../components/Skeleton";
const steps = _onboarding_options.getOnboardingSteps();
const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();
const options = _onboarding_options.getAllSettingsOptions();

const mailingLists = [
  {
    id: 1,
    name: "Food & Beverage",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 2,
    name: "Hospitality",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 3,
    name: "Retail",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 4,
    name: "Education",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 5,
    name: "Healthcare",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 6,
    name: "Financial",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 7,
    name: "Consumer Services",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 8,
    name: "Manufacturing",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 9,
    name: "Government",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 10,
    name: "Transportation",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 11,
    name: "Media",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 12,
    name: "Communications",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
  {
    id: 13,
    name: "Energy",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 14,
    name: "Technology",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 15,
    name: "Other",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Onboard() {
  const [selectedMailingLists, setSelectedMailingLists] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [currentStep, setStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [company, setCompany] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  let profile = _auth.getUserProfile();
  console.log("role2", profile);

  useEffect(() => {}, []);

  const onChangeValue = (e) => {
    e.preventDefault();

    if (e.target.name === "company") {
      setCompany(e.target.value);
    } else if (e.target.name === "first-name") {
      setFirstName(e.target.value);
    } else if (e.target.name === "last-name") {
      setLastName(e.target.value);
    } else {
      setPhone(e.target.value);
    }
  };
  console.log(checked, "checked");
  async function handleCheckboxClick(clickType) {
    if (clickType === "dontshowagain") {
      setChecked(!checked);
    }
    if (clickType === "onboardFinished") {
      if (checked) {
        let ans = await _onboarding_options.setDontShowAgain(!checked);
        console.log(ans, "ansss");
        if (ans) {
          return history.push({
            pathname: "/home",
          });
        }
      } else {
        onFinish();
      }
    }
  }

  const onFinish = async () => {
    setLoader(true);
    if (checked) {
      let ans = await _onboarding_options.setDontShowAgain(!checked);
      console.log(ans, "ansss");
      if (ans) {
        return history.push({
          pathname: "/home",
        });
      }
    } else {
      let data = {
        industry: selectedMailingLists,
        companySize: selectedSize,
        location: selectedLocation,
        role: selectedRole,
        department: selectedDepartment,
      };

      let results = await _onboarding_options.setUserFinishedOnboarding(
        data,
        history
      );
      //console.info("ONBOARDD", results);

      if (results) {
        setLoader(false);
      }

      console.log("My onboard response", data);
    }
  };

  return (
    <div className="flex h-screen bg-white">
      {/* Content */}
      <div className="w-full">
        <div className="min-h-screen  bg-white flex flex-col after:flex-1">
          {/*New Progress Bar*/}
          <div className="md:flex flex-col after:flex-1 hidden">
            <ArrowSteps
              setStep={setStep}
              steps={steps}
              currentStep={currentStep}
            />
          </div>
          {/*Each step's component */}
          {/* <Fade right> */}
          <div className="px-4 py-8">
            {/* This div controls the width of the main content */}

            <div
              id="options_container"
              // style={{
              //   padding: "25px",
              //   minHeight: "60vh",
              //   maxHeight: "60vh",
              // }}
              className={classNames(
                currentStep == 7
                  ? "w-4/12 mx-auto" // null //"px-6 sm:px-6 lg:px-6 py-8 w-full"
                  : "w-4/12 mx-auto",
                "max-h-min"
              )}
            >
              {/* <div className="max-w-md mx-auto"> */}
              {/* <div> */}
              {/*Onboard step 1 - Introduction */}
              {currentStep == 1 && (
                <Fade right>
                  <div
                    className="overflow-y-none"
                    // style={{
                    //   padding: "25px",
                    //   minHeight: "60vh",
                    //   maxHeight: "60vh",
                    // }}
                  >
                    <LandingIntro />{" "}
                  </div>
                </Fade>
              )}
              {/*Onboard step 2 - Your role */}
              {/* {currentStep == 2 && (
                <Fade right>
                  <div className="sm:flex sm:justify-between sm:items-center mb-8">
                    {/* Left: Title */}
              {/* <div className="mb-4 sm:mb-0">
                      <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                        Help us get to know you!
                      </h1>
                    </div>
                  </div>
                  <ContactForm
                   onChange={(e) => {onChangeValue(e)}}
                   company={company}
                   firstName={firstName}
                   lastName={lastName}
                   phone={phone}
                  isOnboarding={true} />
                </Fade> */}
              {/*Onboard step 2 - Your role */}

              {currentStep == 2 && (
                <Fade right>
                  <SingleOptions
                    title={"Role"}
                    options={roles}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedRole}
                    onSelectOption={(ans) => {
                      setSelectedRole(ans);
                      console.log(selectedRole, "role");
                    }}
                  />
                </Fade>
              )}
              {/*Onboard step 3 - Department */}
              {currentStep == 3 && (
                <Fade right>
                  <SingleOptions
                    title={"Department"}
                    options={departments}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedDepartment}
                    onSelectOption={(ans) => setSelectedDepartment(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 4 - Industry */}
              {currentStep == 4 && (
                <Fade right>
                  <SingleOptions
                    title={"Industry"}
                    options={mailingLists}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedMailingLists}
                    onSelectOption={(ans) => setSelectedMailingLists(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 5 - Company Size */}
              {currentStep == 5 && (
                <Fade right>
                  <SingleOptions
                    title={"Company Size"}
                    options={companySize}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedSize}
                    onSelectOption={(ans) => setSelectedSize(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 6 - Location */}
              {currentStep == 6 && (
                <Fade right>
                  <SingleOptions
                    title={"Location"}
                    options={companyLocation}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    selected={selectedLocation}
                    onSelectOption={(ans) => setSelectedLocation(ans)}
                  />
                </Fade>
              )}
              {/*Onboard step 7 - Resources */}
              {/* {currentStep == 8 && (
                <Fade right>
                  <StackedList
                    title={"Other actions"}
                    options={options}
                    currentStep={currentStep}
                    setStep={setStep}
                    steps={steps}
                    onSelectOption={(ans) =}
                  />
                </Fade>
              )} */}

              {/* Checkbox - Don't show again */}
              {currentStep == 1 && (
                <div className="mr-1">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      onClick={() => {
                        handleCheckboxClick("dontshowagain");
                      }}
                      // onClick={_onboarding_options.setDontShowAgain(this)}
                      className="form-checkbox"
                    />
                    <span className="text-sm ml-2">
                      Don't show this wizard again.
                    </span>
                  </label>
                </div>
              )}
            </div>

            {/* Buttons */}
            <div id="buttons" className="w-4/12 mx-auto ">
              <div
                className="mt-20 flex-none md:flex  
               items-center justify-between gap-4 "
              >
                {!loader && (
                  <button
                    className="btn bg-indigo-200 hover:bg-indigo-200 text-white mx-2 my-2 shrink-0  w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                    onClick={() => {
                      if (!loader) {
                        history.push("/home");
                      }
                    }}
                  >
                    Finish later
                  </button>
                )}

                {currentStep > 1 && !loader && (
                  <button
                    //flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                    // to="/onboarding-02"
                    onClick={() =>
                      currentStep > 1 ? setStep(currentStep - 1) : null
                    }
                  >
                    &lt;- Previous Step
                  </button>
                )}
                {!checked ? (
                  <>
                    {currentStep < steps.length ? (
                      <button
                        className="btn bg-lime-500 hover:bg-lime-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                        // to="/onboarding-02"
                        onClick={() => {
                          setStep(currentStep + 1);
                        }}
                      >
                        Step {currentStep + 1} -&gt;
                      </button>
                    ) : (
                      <>
                        {/* {loader ? ( */}
                        <div class="flex justify-center items-center">
                          {/* <img
                              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                              src={Spinner}
                              width="32"
                              height="32"
                              alt="loading.."
                            /> */}
                        </div>
                        {/* ) : ( */}
                        <button
                          className="btn bg-lime-500 hover:bg-lime-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                          exact
                          onClick={onFinish}
                        >
                          {loader && (
                            <Skeleton
                              type="spinner"
                              color="gray"
                              fill="purple"
                              forButton={true}
                            />
                          )}{" "}
                          Finish -&gt;
                        </button>
                        {/* )} */}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {loader ? (
                      <div class="flex justify-center items-center">
                        {/* <img
                          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                          src={Spinner}
                          width="32"
                          height="32"
                          alt="loading.."
                        /> */}
                        <Skeleton type="spinner" color="gray" fill="purple" />
                      </div>
                    ) : (
                      <button
                        className="btn bg-lime-500 hover:bg-lime-600 text-white mx-2 my-2 shrink-0 w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto"
                        exact
                        onClick={onFinish}
                      >
                        <Skeleton
                          type="spinner"
                          color="gray"
                          fill="purple"
                          forButton={true}
                        />{" "}
                        Finish -&gt;
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* </Fade> */}
        </div>
      </div>
    </div>
  );
}

export default Onboard;
