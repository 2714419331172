// export const baseUrl = "http://localhost:9000/api/";

let baseUrl = "";
if (process.env.NODE_ENV === "development") {
  baseUrl = "http://localhost:9001/api/";
} else if (process.env.NODE_ENV === "production") {
  baseUrl = "https://console-api.breachlink.com/api/";
} else if (process.env.NODE_ENV === "beta") {
  baseUrl = "https://console-api.breachlink.com/api/";
}
console.info("CURRENT ENVIRONMENT: ", process.env.NODE_ENV);
console.info("CURRENT BASE URL: ", baseUrl);
export { baseUrl };
