import React, { useRef, useEffect, useState } from "react";
import Transition from "../utils/Transition.js";
import ModalBlank from "./ModalBlank.js";
function ModalForm({ children, id, title, modalOpen, setModalOpen }) {
  const modalContent = useRef(null);
  const [basicModalOpen, setBasicModalOpen] = useState(false);
  const [scrollbarModalOpen, setScrollbarModalOpen] = useState(false);
  const [cookiesModalOpen, setCookiesModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [newsletterModalOpen, setNewsletterModalOpen] = useState(false);
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);
  const [newsModalOpen, setNewsModalOpen] = useState(false);
  const [planModalOpen, setPlanModalOpen] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return;
      setModalOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        {/* Feedback */}
        <div>
          <h2 className="text-2xl text-gray-800 font-bold mb-6">Feedback</h2>
          <div className="flex flex-wrap items-center -m-1.5">
            {/* Success Modal */}
            <div className="m-1.5">
              {/* Start */}
              <button
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                aria-controls="success-modal"
                onClick={(e) => {
                  e.stopPropagation();
                  setSuccessModalOpen(true);
                }}
              >
                Success Modal
              </button>
              <ModalBlank
                id="success-modal"
                modalOpen={successModalOpen}
                setModalOpen={setSuccessModalOpen}
              >
                <div className="p-5 flex space-x-4">
                  {/* Icon */}
                  <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-green-100">
                    <svg
                      className="w-4 h-4 shrink-0 fill-current text-green-500"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
                    </svg>
                  </div>
                  {/* Content */}
                  <div>
                    {/* Modal header */}
                    <div className="mb-2">
                      <div className="text-lg font-semibold text-gray-800">
                        Upgrade your Subscription?
                      </div>
                    </div>
                    {/* Modal content */}
                    <div className="text-sm mb-10">
                      <div className="space-y-2">
                        <p>
                          Semper eget duis at tellus at urna condimentum mattis
                          pellentesque lacus suspendisse faucibus interdum.
                        </p>
                      </div>
                    </div>
                    {/* Modal footer */}
                    <div className="flex flex-wrap justify-end space-x-2">
                      <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSuccessModalOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white">
                        Yes, Upgrade it
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBlank>
              {/* End */}
            </div>

            {/* Danger Modal */}
            <div className="m-1.5">
              {/* Start */}
              <button
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                aria-controls="danger-modal"
                onClick={(e) => {
                  e.stopPropagation();
                  setDangerModalOpen(true);
                }}
              >
                Danger Modal
              </button>
              <ModalBlank
                id="danger-modal"
                modalOpen={dangerModalOpen}
                setModalOpen={setDangerModalOpen}
              >
                <div className="p-5 flex space-x-4">
                  {/* Icon */}
                  <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-red-100">
                    <svg
                      className="w-4 h-4 shrink-0 fill-current text-red-500"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                    </svg>
                  </div>
                  {/* Content */}
                  <div>
                    {/* Modal header */}
                    <div className="mb-2">
                      <div className="text-lg font-semibold text-gray-800">
                        Delete 1 customer?
                      </div>
                    </div>
                    {/* Modal content */}
                    <div className="text-sm mb-10">
                      <div className="space-y-2">
                        <p>
                          Semper eget duis at tellus at urna condimentum mattis
                          pellentesque lacus suspendisse faucibus interdum.
                        </p>
                      </div>
                    </div>
                    {/* Modal footer */}
                    <div className="flex flex-wrap justify-end space-x-2">
                      <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDangerModalOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button className="btn-sm bg-red-500 hover:bg-red-600 text-white">
                        Yes, Delete it
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBlank>
              {/* End */}
            </div>

            {/* Info Modal */}
            <div className="m-1.5">
              {/* Start */}
              <button
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                aria-controls="info-modal"
                onClick={(e) => {
                  e.stopPropagation();
                  setInfoModalOpen(true);
                }}
              >
                Info Modal
              </button>
              <ModalBlank
                id="info-modal"
                modalOpen={infoModalOpen}
                setModalOpen={setInfoModalOpen}
              >
                <div className="p-5 flex space-x-4">
                  {/* Icon */}
                  <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-indigo-100">
                    <svg
                      className="w-4 h-4 shrink-0 fill-current text-indigo-500"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
                    </svg>
                  </div>
                  {/* Content */}
                  <div>
                    {/* Modal header */}
                    <div className="mb-2">
                      <div className="text-lg font-semibold text-gray-800">
                        Create new Event?
                      </div>
                    </div>
                    {/* Modal content */}
                    <div className="text-sm mb-10">
                      <div className="space-y-2">
                        <p>
                          Semper eget duis at tellus at urna condimentum mattis
                          pellentesque lacus suspendisse faucibus interdum.
                        </p>
                      </div>
                    </div>
                    {/* Modal footer */}
                    <div className="flex flex-wrap justify-end space-x-2">
                      <button
                        className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                        onClick={(e) => {
                          e.stopPropagation();
                          setInfoModalOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white">
                        Yes, Create it
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBlank>
              {/* End */}
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}

export default ModalForm;
