import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { _onboarding_options } from "../modules/_onboarding_options";

const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function YesNoQuestions(props) {
  var question = props.question;
  //console.info("THIS IS THE QUESTION", question);
  const questions = props.questions;
  // //console.info("questions", questions);
  const totalQuestions = questions.length;
  const title = props.title;
  const qid = props.qid;
  const answerOpts = props.answerOpts;
  const setCount = props.setCount;
  const [selectedDepartment, setSelectedDepartment] = useState(departments[0]);
  const handleOnChange = (e) => {
    setCount(e);
    setSelectedDepartment(e);
    // //console.info("THIS IS THE QUESTION", question);
    question.your_answer = e;
    // //console.info("THIS IS THE UPDATED QUESTION", question);
    // props.setUpdatedQuestion(question); //pass answer to parent
    //Find index of specific object using findIndex method.

    props.UpdateHistoryObject(question); //pass answer to parent
    //console.info("Updated History with question", qid);
    //console.info("Total Questions", totalQuestions);
    qid < totalQuestions && props.setCurrentQuestion(props.currentQuestion + 1);
    // : props.FinishAssessment(question);
    //save question object with "your answer"
    // //console.info(e);
  };
  return (
    <div className="mt-10 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
      {answerOpts.map((department, idx) => (
        <RadioGroup
          key={idx}
          value={question.your_answer}
          onChange={handleOnChange}
        >
          <RadioGroup.Option
            key={department.id}
            value={department.id}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-gray-300",
                active ? "ring-2 ring-indigo-500" : "",
                // "text-center btn bg-indigo-200 hover:bg-indigo-200 text-white ml-auto",
                "items-center justify-center relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <div className="flex-1 flex  items-center">
                  <div className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-medium text-gray-900 "
                    >
                      {department.title}
                    </RadioGroup.Label>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                <div
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-indigo-500" : "border-transparent",
                    "absolute -inset-px rounded-lg pointer-events-none"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      ))}
    </div>
    //   </div>
    // </div>
  );
}

export default YesNoQuestions;
