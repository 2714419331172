import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Tabs from "./component/Tabs";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DeleteButton from "../partials/actions/DeleteButton";
import DateSelect from "../components/DateSelect";
import FilterButton from "../components/DropdownFilter";
// import CustomersTable from "../partials/customers/CustomersTable";
import PaginationClassic from "../components/PaginationClassic";
import { customers } from "../modules/_customers";
import ModalForm from "../components/ModalForm";
import { _assessment } from "../modules/_assessment";
import ModalBasic from "../components/ModalBasic";
import AnswerList from "../components/AnswerList";
import AuditTable from "./AuditTable";
import { audit } from "../modules/_audit";
import Table from "../components/Table";
import DropdownFull from "../components/DropdownFull";
import { dashboard } from "../modules/_dashboard";
import { features } from "../modules/features";
import Skeleton from "../components/Skeleton";
import Button from "../components/Button";
import { Switch } from "@headlessui/react";
import DataEntryDialog from "../components/DataEntryDialog";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function FeatureFlagManage() {
  const tabs = [
    { name: "Regulations", href: "../campaigns", current: false },
    { name: "Assessment history", href: "ecommerce/customers", current: true },
  ];
  const [showModal, setShowModal] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [data, setData] = useState("loading");
  const [allData, setAllData] = useState("loading");
  const [killSwitch, setKillSwitch] = useState({});
  const [forceOn, setForceOn] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalItem, setModalItems] = useState({
    questions: [{ questions: [] }],
  });
  const [filterOptions, setFilterOptions] = useState("loading");
  const [featureFlags, setFeatureFlags] = useState("loading");
  const [isChecked, setIsChecked] = useState([]);
  const [topUsers, setTopUsers] = useState({});
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  useEffect(() => {
    (async () => {
      // let allData;
      // let getFeatures = [
      //   {
      //     FeatureName: "Upgrade",
      //     isOn: true,
      //     isOnInDev: true,
      //     isOnInBeta: true,
      //     isOnInProd: false,
      //     isOnForDsuOnly: false,
      //     isOnForBetaUser: false,
      //     status: "released",
      //     tiers: ["Free", "Grow", "Scale"],
      //   },
      //   {
      //     FeatureName: "Settings",
      //     isOn: true,
      //     isOnInDev: true,
      //     isOnInBeta: true,
      //     isOnInProd: true,
      //     isOnForDsuOnly: false,
      //     isOnForBetaUser: false,
      //     status: "released",
      //     tiers: ["Free", "Grow", "Scale"],
      //   },
      //   {
      //     FeatureName: "Send Assessment",
      //     isOn: true,
      //     isOnInDev: true,
      //     isOnInBeta: true,
      //     isOnInProd: true,
      //     isOnForDsuOnly: true,
      //     isOnForBetaUser: false,
      //     status: "beta",
      //     tiers: ["Free", "Grow", "Scale"],
      //   },
      //   {
      //     FeatureName: "Marketplace",
      //     isOn: true,
      //     isOnInDev: true,
      //     isOnInBeta: true,
      //     isOnInProd: true,
      //     isOnForDsuOnly: false,
      //     isOnForBetaUser: false,
      //     status: "beta",
      //     tiers: ["Free", "Grow", "Scale"],
      //   },
      //   {
      //     FeatureName: "Employees",
      //     isOn: true,
      //     isOnInDev: true,
      //     isOnInBeta: true,
      //     isOnInProd: true,
      //     isOnForDsuOnly: true,
      //     isOnForBetaUser: false,
      //     status: "beta",
      //     tiers: ["Grow", "Scale"],
      //   },
      //   {
      //     FeatureName: "Knowledge Center",
      //     isOn: true,
      //     isOnInDev: true,
      //     isOnInBeta: true,
      //     isOnInProd: true,
      //     isOnForDsuOnly: true,
      //     isOnForBetaUser: false,
      //     status: "beta",
      //     tiers: ["Free", "Grow", "Scale"],
      //   },
      // ];
      let getFeatures = await features.getFeatureFlags();

      if (getFeatures) {
        console.log("Got features", getFeatures);
        let killSwitch = getFeatures.find(
          (f) => f.FeatureName === "Kill Switch"
        );

        let forceOn = getFeatures.find((f) => f.FeatureName === "Force On");
        setKillSwitch(killSwitch);
        setForceOn(forceOn);
        setFeatureFlags(getFeatures);
        setAllData(getFeatures);
        setData(getFeatures);
        let filterOptionsEmail = [
          { id: 0, period: "AAll", display: "All Features" },
        ];
        getFeatures.map((feature) => {
          console.info("FEATURE -->", feature);

          filterOptionsEmail.push({
            id: feature._id,
            period: feature.FeatureName,
            display: feature.FeatureName,
          });
        });

        console.info("filterOptionsEmail", filterOptionsEmail);
        setFilterOptions(filterOptionsEmail);
      }

      setLoading(false);
    })();
  }, []);

  const getFeatureFlagOptions = () => {
    let getFeatures = [
      { id: 1, FeatureName: "Settings", FeatureFlag: true, AdminFlag: true },
      { id: 2, FeatureName: "Feature2", FeatureFlag: true, AdminFlag: false },
    ];
    let filterOptionsEmail = [
      { id: 0, period: "AAll", display: "All Features" },
    ];
    featureFlags.map((feature) => {
      console.info("FEATURE -->", feature);

      filterOptions.push({
        id: feature._id,
        period: feature.FeatureName,
        display: feature.FeatureName,
      });
      setFilterOptions(filterOptionsEmail);
    });
  };

  // useEffect(() => {
  //   let getFeatures = [
  //     { id: 1, FeatureName: "Settings", FeatureFlag: true, AdminFlag: true },
  //     { id: 2, FeatureName: "Feature2", FeatureFlag: true, AdminFlag: false },
  //   ];

  //   console.log("Got features", getFeatures);
  //   setFeatureFlags(getFeatures);
  //   console.log("useEffect");
  // }, []);

  // add all feature flags to the filter options by name

  const setCheckedValue = async (e, feature, key, value, oldValue) => {
    // console.log("Set checked value", e, feature, key, "value", value, oldValue);
    let newFeatureFlags = [...featureFlags];
    let newFeature = newFeatureFlags.find((f) => f.FeatureName === feature);
    if (newFeature) {
      console.log("setCheckedValue Found newFeature", newFeature);
    }

    newFeature[key] = !oldValue;
    console.log("setCheckedValue newFeature", newFeature);
    if (feature === "Kill Switch") {
      console.log("setCheckedValue Kill Switch to", newFeature);
      setKillSwitch(newFeature);
    } else if (feature === "Force On") {
      setForceOn(newFeature);
    }
    let update = await features.updateFeatureFlag(newFeature);
    //find and update the feature flag in state
    let updatedFeatureFlags = featureFlags.map((featureFlag) => {
      if (featureFlag.FeatureName === newFeature.FeatureName) {
        console.log("setCheckedValue Found updated feature flag", featureFlag);
        console.log(
          "setCheckedValue Updating",
          featureFlag.FeatureName,
          "from",
          oldValue,
          "to",
          !oldValue
        );
        featureFlag[key] = !oldValue;
      }
      return featureFlag;
    });
    // featureFlags[key] = value;
    //update state with new value

    setFeatureFlags(updatedFeatureFlags);
    setData(updatedFeatureFlags);
    setAllData(updatedFeatureFlags);
    // await updateFeatureFlag(feature, key, !oldValue);
  };
  const updateFeatureFlag = async (f) => {
    console.log("updateFeatureFlag newFeature Update feature flag", f);
    let updateFeature = await features.updateFeatureFlag(f);
    console.log(" updateFeatureFlag  feature flag", updateFeature);
  };

  const handleSelectedItems = (e, item) => {
    console.log("handleSelectedItems", e, item);
    setSelectedItems(
      e.target.checked
        ? [...selectedItems, e.target.value]
        : selectedItems.filter((item) => item !== e.target.value)
    );
    // setSelectedItems([...selectedItems, e.target.value]);
  };

  const handleFilter = (e) => {
    setSelectedItems(e);

    if (e === "AAll") {
      // console.info("Filter All Data", e);
      setData(allData);
    } else {
      // console.info("Filter By Person", e, allData);
      setData(allData.filter((item) => item.FeatureName === e));
    }
  };

  const setModalItem = (item) => {
    setModalItems(item);
    showHideModal();
  };

  const showHideModal1 = () => {
    //console.info("ATTENTION - CALLING showHideModal");

    setShowModal(false);
  };

  const showHideModal = () => {
    // //console.info("ATTENTION - MODAL ITEM PASSED", d);
    // setModalItem(d);
    setShowModal(!showModal);
    //console.info(
    //   "ATTENTION Show Modal is",
    //   showModal,
    //   "Changing to",
    //   !showModal
    // );
  };

  const getKeyDisplayName = (key) => {
    switch (key) {
      case "FeatureName":
        return "Feature Name";
      case "isOn":
        return "On";
      case "isOnInDev":
        return "On in Dev";
      case "isOnInBeta":
        return "On in Beta";
      case "isOnInProd":
        return "On in Prod";
      case "isOnForDsuOnly":
        return "On for DSU Only";
      case "isOnForBetaUser":
        return "On for Beta User";
      case "status":
        return "Status";
      case "tiers":
        return "Tiers";
      default:
        return key;
    }
  };

  const handleCreateFlag = async (newFlag) => {
    //if the feature flag already exists, return false\
    if (newFlag) {
      let existingFlag = featureFlags.find(
        (featureFlag) =>
          featureFlag.FeatureName.toLowerCase() === newFlag.toLowerCase()
      );
      if (existingFlag) {
        return "Feature flag name already exists";
      }

      let template = {
        FeatureName: newFlag,
        isOn: true,
        isOnInDev: true,
        isOnInBeta: false,
        isOnInProd: false,
        isOnForDsuOnly: false,
        isOnForBetaUser: false,
        status: "development",
        tiers: ["Free", "Grow", "Scale"],
        auth: ["Super Admin"],
      };
      const created = await features.create(template);
      console.log("Created new flag", created);
      if (created) {
        updateFeatureFlagState(created);
        return true;
      } else {
        return false;
      }
    } else {
      return "Please enter a feature flag name";
    }
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     console.log("Create new flag", newFlag);
    //     resolve(true);
    //   }, 1000);
    // });
  };

  const updateFeatureFlagState = (feature) => {
    console.log("updateFeatureFlagState", feature);
    let newFeatureFlags = [...featureFlags];
    newFeatureFlags.push(feature);
    console.log("updateFeatureFlagState newFeatureFlags", newFeatureFlags);
    setFeatureFlags(newFeatureFlags);
    setData(newFeatureFlags);
    setAllData(newFeatureFlags);
  };

  console.info("Selected Items", selectedItems);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto m-h-96 overflow-hidden">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
            Feature Flags
          </h1>
        </div>

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <DataEntryDialog
            open={createDialogOpen}
            setOpen={setCreateDialogOpen}
            handleChange={null}
            handleOnSubmit={async (value) => {
              const res = await handleCreateFlag(value);
              if (res) {
                return res;
              }
            }}
          />
          <Button handleClick={() => setCreateDialogOpen(true)} size="small">
            Create new flag
          </Button>
        </div>
      </div>
      {/* Filter Row */}
      {/* <div className="flex flex-col"> */}
      <div className=" sm:flex sm:justify-between sm:items-center mb-8">
        {/* grid grid-flow-col  sm:auto-cols-max justify-start sm:justify-end gap-2 */}
        <div className="w-full ">
          <div className="w-1/3 md:w-1/2 flex mr-4">
            {filterOptions == "loading" ? (
              <Skeleton type="spinner" />
            ) : (
              <DropdownFull
                options={filterOptions}
                handleFilter={handleFilter}
              />
            )}
          </div>
        </div>
        {/* </div> */}
        {/*right actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-1">
          <Switch
            // disabled={key !== "isOn" && !item.isOn}
            checked={killSwitch ? killSwitch.isOn : false}
            onChange={(e) =>
              setCheckedValue(e, "Kill Switch", "isOn", "on", killSwitch.isOn)
            }
            // checked={isChecked}
            // onChange={setIsChecked}
            className={classNames(
              killSwitch && killSwitch.isOn ? "bg-red-500" : "bg-indigo-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                killSwitch && killSwitch.isOn
                  ? "translate-x-5"
                  : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
          <label className="ml-3 mr-16">
            <span className="text-sm font-medium text-gray-900">
              {killSwitch && killSwitch.isOn
                ? "Kill switch is ON"
                : "Kill switch is OFF"}
            </span>
          </label>

          <Switch
            // disabled={key !== "isOn" && !item.isOn}
            checked={forceOn && forceOn.isOn}
            onChange={(e) =>
              setCheckedValue(e, "Force On", "isOn", "on", forceOn.isOn)
            }
            // checked={isChecked}
            // onChange={setIsChecked}
            className={classNames(
              forceOn && forceOn.isOn ? "bg-lime-500" : "bg-indigo-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                forceOn && forceOn.isOn ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
          <label className="ml-3 mr-16">
            <span className="text-sm font-medium text-gray-900">
              {forceOn && forceOn.isOn ? "All features ON" : "Force on is OFF"}
            </span>
          </label>
        </div>
      </div>
      {/* Body */}
      <div className="mb-6">
        {loading && <Skeleton type="spinner" />}
        <div classname="flex flex-col space-y-6 mb-6">
          {!loading &&
            data
              .sort((a, b) => (a.FeatureName > b.FeatureName ? 1 : -1))
              .map((item) => {
                // item.FeatureName != "Kill Switch" && item.FeatureName !== "Force On" && (
                if (
                  item.FeatureName != "Kill Switch" &&
                  item.FeatureName !== "Force On"
                ) {
                  return (
                    <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative mb-6">
                      <div className="px-5 py-4">
                        <label className="text-base font-medium text-gray-900">
                          {item.FeatureName}
                        </label>
                        <p className="text-sm leading-5 text-gray-500">
                          Select your feature flags
                        </p>
                        <fieldset className="mt-4">
                          <legend className="sr-only">Feature Flags</legend>
                          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            {Object.keys(item).map((key, value) => {
                              if (
                                key !== "FeatureName" &&
                                key !== "status" &&
                                key !== "tiers" &&
                                key !== "_id"
                              ) {
                                console.log(
                                  "KEY",
                                  key,
                                  "VALUE",
                                  value,
                                  "itemKey",
                                  item[key]
                                );
                                return (
                                  <div className="flex items-center">
                                    <input
                                      id={`${item.FeatureName}-${key}`}
                                      name={`${item.FeatureName}-${key}`}
                                      type="checkbox"
                                      className={`h-4 w-4 text-indigo-600 transition duration-150 ease-in-out ${
                                        key !== "isOn" && !item.isOn
                                          ? "cursor-not-allowed bg-gray-100 border-gray-300 text-gray-300"
                                          : ""
                                      }`}
                                      // className="h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                      // value={item[key]}
                                      disabled={key !== "isOn" && !item.isOn}
                                      checked={item[key]}
                                      onChange={(e) =>
                                        setCheckedValue(
                                          e,
                                          item.FeatureName,
                                          key,
                                          e.target.value,
                                          item[key]
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={key}
                                      className="ml-3 block text-sm leading-5 font-medium text-gray-700"
                                    >
                                      {getKeyDisplayName(key)}
                                    </label>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  );
                }
              })}
        </div>
      </div>
    </div>
  );
}

export default FeatureFlagManage;
