import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { _auth } from "../modules/_auth";
import DSULogo from "../images/DSU-loader-log.jpg";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";

function ResetPasswordEmail(props) {
  const mvp = false;
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token, id } = useParams();
  const [loader, setLoader] = useState(false);

  console.log(token, "token");
  const resetPassword = async (event) => {
    setLoader(true);
    event.preventDefault();
    //console.info(event);
    let path = "/home";

    let profile = await _auth.resetPasswordEmail(
      id,
      token,
      password,
      confirmPassword,
      history
    );
    console.log(profile, "profile");
    if (profile === false) {
      setLoader(false);
      setIsAuthenticated(true);
    } else {
      setLoader(false);
      history.push("/");
    }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            <div className="max-w-sm mx-auto px-4">
              <img className="max-w-18 mx-auto px-4 py-8" src={DSULogo} />
            </div>
            <div className="max-w-sm mx-auto px-4 ">
              <h1 className="text-2xl text-gray-800 font-bold mb-6">
                Set New Password
              </h1>
              {/* Form */}
              <form onSubmit={resetPassword}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      New Password
                    </label>
                    <input
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-input w-full"
                      type="password"
                      autoComplete="on"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Retype New Password
                    </label>
                    <input
                      id="confirm-password"
                      className="form-input w-full"
                      type="password"
                      autoComplete="on"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  {loader ? (
                    <div class="flex justify-center items-center">
                      <img
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                        src={Spinner}
                        width="32"
                        height="32"
                        alt="loading.."
                      />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      class="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                      // onClick={auth}
                    >
                      Reset Password
                    </button>
                  )}
                </div>
              </form>

              {/* Footer */}
              {isAuthenticated && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  Your token is Invalid - Please contact admin.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResetPasswordEmail;
