import React from "react";
import Button from "./Button";
// import Skeleton from "./Skeleton";

function MarketplaceCard({ children, ...props }) {
  // //console.info("Marketplace Item", props);
  const data = props.data;

  const name = data.title;
  const category = data.category;
  const description = data.content;
  const services = data.services;
  const website = data.website;
  const vendor_email = data.email;
  const image = data.image;
  const href = data.href;
  const rating = data.rating;
  const connections = data.connections;
  const setCompany = () => {
    // //console.info("COMPANY", name);
    props.setCompanyValues(name);
    props.setVendorEmail(vendor_email);
  };

  return (
    <div className="col-span-full xl:col-span-4 2xl:col-span-4  bg-white shadow-md rounded-sm border border-gray-200">
      {/* Card content */}
      <div className="flex flex-col h-full p-5">
        <div className="grow">
          <header className="flex items-center mb-4">
            {/* <div className="w-10 h-10 rounded-full shrink-0 bg-gradient-to-tr from-indigo-500 to-indigo-300 mr-3">
              <svg
                className="w-10 h-10 fill-current text-white"
                viewBox="0 0 40 40"
              >
                <path d="M26.946 18.005a.583.583 0 00-.53-.34h-6.252l.985-3.942a.583.583 0 00-1.008-.52l-7 8.167a.583.583 0 00.442.962h6.252l-.984 3.943a.583.583 0 001.008.52l7-8.167a.583.583 0 00.087-.623z" />
              </svg>
            </div> */}
            <h3 className="text-lg text-gray-800 font-semibold">{name}</h3>
          </header>
          <div className="text-sm">{description}</div>
          {services.length > 0 && (
            <div className="space-x-0.5 text-sm mt-2 font-medium">
              <span>Services:</span>{" "}
              {services.map((svc) => {
                return (
                  <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
                    {svc}
                  </span>
                );
              })}
            </div>
          )}
        </div>
        {/* Card footer */}
        <footer className="mt-4">
          <div className="flex flex-wrap justify-between items-center">
            {/* Left side */}
            <div className="flex space-x-3">
              {/* <div className="flex items-center text-gray-400">
                <svg
                  className="w-4 h-4 shrink-0 fill-current mr-1.5"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.14 9.585a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.979 4.979 0 01-1.113-.135 4.436 4.436 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM10.125 2.188a2.5 2.5 0 10-.4 2.014 5.027 5.027 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.023 7.023 0 00-4.469-5.396zM4.663 10.5a2.49 2.49 0 00-1.932-1.234 4.624 4.624 0 01-.037-.516 4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016A6.989 6.989 0 00.694 8.75c.004.391.04.781.11 1.166a2.5 2.5 0 103.86.584z" />
                </svg>
                <div className="text-sm text-gray-500">4K+</div>
              </div>
              <div className="flex items-center text-yellow-500">
                <svg
                  className="w-4 h-4 shrink-0 fill-current mr-1.5"
                  viewBox="0 0 16 16"
                >
                  <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                </svg>
                <div className="text-sm text-yellow-600">4.7</div>
              </div> */}
            </div>
            {/* Right side */}

            <button
              value={name}
              className="btn-sm bg-lime-500 text-white hover:background-lime-400 shadow-sm flex items-center"
              onClick={(e) => {
                e.stopPropagation();
                setCompany();
                props.setShowModal(true);
              }}
            >
              <span>Connect</span>
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default MarketplaceCard;
