import React from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import DSULogo from "../images/logo.png";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import Button from "../components/Button";
import { audit } from "../modules/_audit";

function Signin() {
  const mvp = false;
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [loginMessage, setLoginMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const auth = async (event) => {
    setLoader(true);

    event.preventDefault();
    //console.info(event);
    var u = event.target.elements.email.value;
    var p = event.target.elements.password.value;
    //console.info(u, p);

    let path = "/onboarding";

    let loginArr = await _auth.login(u, p);
    let isSuccessfulAuth = loginArr[0];
    let profile = loginArr[1];

    //console.info("GETTING PROFILE", isSuccessfulAuth, profile);

    if (isSuccessfulAuth) {
      _auth.setUserProfile(profile);
      //audit login success
      // audit.logAction("Login Success");
      console.log("User Profile", profile);
      //remove this check until we fix database
      if (profile.MFA === "true") {
        console.log("MFS SET");
        path = "/mfa";
        return history.push({
          state: { profile: profile },
          pathname: path,
        });
      } else {
        console.log("MFS NOT SET");
        return history.push({
          state: { profile: profile },
          pathname: "/home",
        });
      }
    } else {
      // audit.logAction("Login Failed");
      console.log("Not Authenticated");
      setLoader(false);
      setIsAuthenticated(false);
      setLoginMessage(profile);
      // if (isSuccessfulAuth == false) {
      //   setLoader(false);
      //   setIsAuthenticated(false);
      //   setLoginMessage("Invalid username or password");
      // } else if (profile === "This user is disabled") {
      //   setLoader(false);
      //   setIsAuthenticated(false);
      //   setLoginMessage("This user is disabled");
      // } else {
      //   // let profile = _auth.getUserProfile(u);
      //   // let role = _auth.getUserRole(u);
    }

    // if (profile != false) {
    //   // let profile = _auth.getUserProfile(u);
    //   // let role = _auth.getUserRole(u);
    //   _auth.setUserProfile(profile);
    //   console.log(profile, "prooo");
    //   if (profile.MFA) {
    //     path = "/mfa";
    //     return history.push({
    //       state: { profile: profile },
    //       pathname: path,
    //     });
    //   }
    //   // }
    //   // else if (profile.dnd || profile.onBoardCompleted) {
    //   //   console.log("im runing");
    //   //   path = "/home";
    //   //   return history.push({
    //   //     state: { profile: profile },
    //   //     pathname: path,
    //   //   });
    //   // } else {
    //   //   setLoader(false);
    //   //   return history.push({
    //   //     state: { profile: profile },
    //   //     pathname: path,
    //   //   });
    //   // }
    // } else {
    //   setLoader(false);
    //   setIsAuthenticated(false);
    // }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex grow-0 justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex grow-0 flex-col content-center justify-center">
            <div className="max-w-sm mx-auto px-4">
              <img className="max-w-18 mx-auto px-4 py-8" src={DSULogo} />
            </div>
            <div className="max-w-sm mx-auto px-4 ">
              {/* <h1 className="text-3xl text-gray-800 font-bold mb-6">Sign in</h1> */}
              {/* Form */}
              <form onSubmit={auth}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      id="email"
                      className="form-input w-full"
                      type="email"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      className="form-input w-full"
                      type="password"
                      autoComplete="on"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    {/* <Link
                      className="text-sm underline hover:no-underline"
                      to="/forgot-password"
                    >
                      Forgot Password?
                    </Link> */}
                  </div>
                  {/* {loader ? (
                    <div class="flex justify-center items-center">
                      <img
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                        src={Spinner}
                        width="32"
                        height="32"
                        alt="loading.."
                      />
                    </div>
                  ) : ( */}
                  <Button
                    enabled={!loader}
                    fullWidth
                    size="large"
                    loader={loader}
                    type="submit"
                    className="btn btn-primary"
                    // handleClick={null}
                  >
                    Sign in
                  </Button>
                  {/* // <button
                    //   type="submit"
                    //   class="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"

                    // >
                    //   Sign in
                    // </button>
                  // )} */}

                  {/* After login and MFA, check to see if onboarding has completed or 
            if the user has checked the "Don't show this wizard again" checkbox.
            If the user has not checked the do not show again box and has no completed onboarding, redirect to the onboarding page.
             */}
                  {/* <Link
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                    to="/onboard"
                  >
                    Sign In
                  </Link> */}
                </div>
              </form>

              {/* Footer */}
              {isAuthenticated === false && (
                <div className="flex grow-0 items-center justify-center mt-6  text-red-500 text-sm">
                  {/* Username or password is incorrect. */}
                  {loginMessage}
                </div>
              )}
              {/* <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{" "}
                  <Link
                    className="font-medium text-indigo-500 hover:text-indigo-600"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Signin;
