import React from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import Skeleton from "../../components/Skeleton";
function AnalyticsCard05(props) {
  const title = props.title;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const ref = useRef(null);
  const data = props.data;
  const upper = props.uppercase;
  const loading = props.loading;
  const labels = props.labels;
  const values = props.values;
  //console.info("AnalyticsCard05 DATA", data);

  const getWidth = (val) => {
    const highVal = values[0];
    const lowVal = values[values.length - 1];
    let maxW = 0;
    if (ref.current) {
      maxW = ref.current.offsetWidth;
    } else {
      maxW = 800;
    }
    let width = ((val + 5 - lowVal) / (highVal - lowVal + 5)) * maxW;
    // let width = (maxW * val) / highVal;
    width = (width / maxW) * 100 + "%";

    return width;
  };

  return (
    <div
      className={`flex  flex-col col-span-full sm:col-span-6 md:col-span-${colSpan}  lg:col-span-${colSpan} xl:col-span-${colSpan}  grow-0 row-span-${rowspan} bg-white shadow-lg rounded-sm border border-gray-200`}
    >
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">{props.title}</h2>
      </header>
      {loading ? (
        <Skeleton type="spinner" color="gray" fill="purple" />
      ) : (
        <div className="grow p-3">
          <div className="flex flex-col h-full">
            {/* Card content */}
            <div className="grow">
              <ul className="flex justify-between text-xs uppercase text-gray-400 font-semibold px-2 space-x-2">
                <li>Name</li>
                <li>Total</li>
              </ul>

              <ul className="space-y-1 text-sm text-gray-800 mt-3 mb-4 overflow-x-hidden overflow-y-auto">
                {/* Item */}
                {data.length > 0 &&
                  data !== "loading" &&
                  data.map((item, index) => {
                    const key = item.group;
                    const val = item.count;
                    //console.info("AN5 KVP", key, val);
                    return (
                      <li
                        id="progress"
                        ref={ref}
                        className="relative px-2 py-1 "
                      >
                        <div
                          className="absolute inset-0 bg-indigo-100"
                          aria-hidden="true"
                          style={{
                            width: getWidth(val, props.title),
                          }}
                        ></div>
                        <div className="relative flex justify-between space-x-2">
                          <div
                            className={`${upper == true ? "uppercase" : null} `}
                          >
                            {key}
                          </div>
                          <div className="font-medium">{val}</div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            {/* Card footer */}
            <div className="text-center pt-4 pb-1 border-t border-gray-100">
              {/* <Link
              className="text-sm font-medium text-indigo-500 hover:text-indigo-600"
              to="#0"
            >
              See All -&gt;
            </Link> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AnalyticsCard05;
