import React, { useState } from "react";
import Skeleton from "./Skeleton";
import { audit } from "../modules/_audit";

function Button(props) {
  const loader = props.loader;
  const loading = props.loader;
  const disabled = props.disabled;
  const full = props.fullWidth;
  const isAudit = props.audit;
  const action = props.action;
  const iconButton = props.iconButton;
  const auditDetails = props.details ? props.details : {};

  // //console.info("Button props", auditDetails, props.details);

  const handleAuditClick = (e) => {
    audit.logAction(action, auditDetails);
    props.handleClick(e);
  };

  return (
    <button
      className={
        iconButton
          ? null
          : `${
              !disabled ? "bg-indigo-500 hover:bg-indigo-600 " : "bg-gray-300"
            } btn-sm  text-white ${full ? "w-full" : ""}`
      }
      onClick={(e) =>
        props.handleClick
          ? isAudit
            ? handleAuditClick(e)
            : props.handleClick(e)
          : isAudit
          ? audit.logAction(action, auditDetails)
          : null
      }
      disabled={disabled}
    >
      {loading && (
        <Skeleton type="spinner" color="gray" fill="purple" forButton={true} />
      )}
      {props.children}
    </button>
  );
}

export default Button;
