import React from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { _auth } from "../modules/_auth";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import DSULogo from "../images/DSU-loader-log.jpg";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";

function ForgotPassword() {
  const mvp = false;
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [passwordMatched, isPasswordMatched] = useState(true);
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const resetPassword = async (event) => {
    setLoader(true);
    event.preventDefault();
    //console.info(event);
    let path = "/home";

    if (password !== confirmPassword) {
      isPasswordMatched(false);
    }

    let profile = await _auth.resetPassword(
      otp,
      password,
      confirmPassword,
      history
    );
    if (profile === false) {
      setLoader(false);
      setIsAuthenticated(false);
    } else {
      setLoader(false);
    }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            <div className="max-w-sm mx-auto px-4">
              <img className="max-w-18 mx-auto px-4 py-8" src={DSULogo} />
            </div>
            <div className="max-w-sm mx-auto px-4 ">
              <h1 className="text-2xl text-gray-800 font-bold mb-6">
                Reset Password
              </h1>
              {/* Form */}
              <form onSubmit={resetPassword}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Verification Code
                    </label>
                    <input
                      id="Otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="form-input w-full"
                      type="number"
                      autoComplete="on"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      New Password
                    </label>
                    <input
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-input w-full"
                      type="password"
                      autoComplete="on"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Retype New Password
                    </label>
                    <input
                      id="confirm-password"
                      className="form-input w-full"
                      type="password"
                      autoComplete="on"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  {loader ? (
                    <div class="flex justify-center items-center">
                      <img
                        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                        src={Spinner}
                        width="32"
                        height="32"
                        alt="loading.."
                      />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      class="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                      // onClick={auth}
                    >
                      Reset Password
                    </button>
                  )}

                  {/* After login and MFA, check to see if onboarding has completed or 
            if the user has checked the "Don't show this wizard again" checkbox.
            If the user has not checked the do not show again box and has no completed onboarding, redirect to the onboarding page.
             */}
                  {/* <Link
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                    to="/onboard"
                  >
                    Sign In
                  </Link> */}
                </div>
              </form>

              {/* Footer */}
              {passwordMatched === false && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  Please recheck your password.
                </div>
              )}
              {isAuthenticated === false && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  Username or password is incorrect.
                </div>
              )}
              {/* <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{" "}
                  <Link
                    className="font-medium text-indigo-500 hover:text-indigo-600"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ForgotPassword;
