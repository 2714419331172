import React from "react";
import { Link } from "react-router-dom";
// import LineChart from "../../charts/LineChart01";
import LineChart from "../../charts/RiskOverTimeChart";
import Icon from "../../images/icon-01.svg";
import EditMenu from "../../components/DropdownEditMenu";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";
import { commandRegistryModule } from "@antv/xflow-core";
import Skeleton from "../../components/Skeleton";

function FinDashboardCard01(props) {
  const loading = props.loading;
  const data = props.data;
  console.info(props.title, "Labels", props.labels);
  console.info(props.title, "VALUES", props.values);
  let chartData = {};

  const chartColor = () => {
    if (props.change > -1) {
      return `rgba(${hexToRGB(
        tailwindConfig().theme.colors.emerald[500]
      )}, 0.08)`;
    } else {
      return `rgba(${hexToRGB(tailwindConfig().theme.colors.rose[500])}, 0.08)`;
    }
  };

  const lineColor = () => {
    if (props.change > -1) {
      return tailwindConfig().theme.colors.emerald[500];
    } else {
      return tailwindConfig().theme.colors.rose[500];
    }
  };

  chartData = {
    labels: props.labels
      ? props.labels
      : [
          // "09-10-2022",
          // "09-11-2022",
          // "09-12-2022",
          // "12-01-2020",
          // "01-01-2021",
          // "02-01-2021",
          // "03-01-2021",
          // "04-01-2021",
          // "05-01-2021",
          // "06-01-2021",
          // "07-01-2021",
          // "08-01-2021",
          // "09-01-2021",
          // "10-01-2021",
          // "11-01-2021",
          // "12-01-2021",
          // "01-01-2022",
          // "02-01-2022",
          // "03-01-2022",
          // "04-01-2022",
          // "05-01-2022",
          // "06-01-2022",
          // "07-01-2022",
          // "08-01-2022",
          // "09-01-2022",
          // "10-01-2022",
          // "11-01-2022",
          // "12-01-2022",
          // "01-01-2023",
        ],
    datasets: [
      // Line
      {
        data: props.values ? props.values : [],
        fill: true,
        backgroundColor: chartColor(),
        borderColor: lineColor(), //tailwindConfig().theme.colors.rose[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: lineColor(), // tailwindConfig().theme.colors.rose[500],
        clip: 20,
      },
    ],
  };

  return (
    // <div className="flex flex-col col-span-full sm:col-span-6 md:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-sm border border-gray-200">
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-sm border border-slate-200">
      <div className="px-5 pt-5">
        <header>
          <h3 className="text-sm font-semibold text-slate-500 uppercase mb-1">
            <span className="text-slate-800">{props.title}</span>
          </h3>
          {loading ? (
            // <Skeleton type="spinner" color="gray" fill="purple" />
            <Skeleton type="spinner" />
          ) : (
            <div>
              <div className="text-2xl font-bold text-slate-800 mb-1">
                {props.currency}
                {props.data}
              </div>
              <div className="text-sm">
                <span
                  className={`font-medium text-${
                    props.data > -1 ? "emerald" : "red"
                  }-500`}
                >
                  {props.currency}
                  {props.change} ({props.changePercent}%)
                </span>{" "}
                - since yesterday
              </div>
            </div>
          )}
        </header>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        {loading ? null : (
          <LineChart
            data={chartData}
            width={286}
            height={98}
            currency={props.currency}
          />
        )}
      </div>
    </div>
    // </div>
  );
}

export default FinDashboardCard01;
