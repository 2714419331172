/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
// import Logo from "../SVG/logowhite.svg";
import {
  CogIcon,
  CollectionIcon,
  HomeIcon,
  MenuAlt2Icon,
  PhotographIcon,
  PlusSmIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
  UsersIcon,
  BadgeCheckIcon,
  FlagIcon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import logo from "../images/BreachConnectLogoSidebar.png";
const sidebarNavigation = [
  { name: "Home", href: "#", icon: HomeIcon, current: false },
  { name: "All Files", href: "#", icon: ViewGridIcon, current: false },
  { name: "Photos", href: "#", icon: PhotographIcon, current: true },
  { name: "Shared", href: "#", icon: UserGroupIcon, current: false },
  { name: "Albums", href: "#", icon: CollectionIcon, current: false },
  { name: "Settings", href: "#", icon: CogIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NarrowSidebar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const navigationItems = [
    {
      name: "Dashboard",
      icon: (
        <HomeIcon className="mr-4  h-6 w-6 text-gray-300 " aria-hidden="true" />
      ),
      href: "/home",
      current: true,
    },
    // {
    //   name: "People",
    //   icon: (
    //     <UsersIcon
    //       className="mr-4 flex-shrink-0 h-6 w-6 text-gray-300"
    //       aria-hidden="true"
    //     />
    //   ),
    //   href: "/profiles",
    //   count: 4,
    //   current: false,
    // },

    {
      name: "Audit",
      icon: (
        <BadgeCheckIcon
          className="mr-4 flex-shrink-0 h-6 w-6 text-gray-300"
          aria-hidden="true"
        />
      ),
      href: "/audit",
      count: 3,
      current: false,
    },
    {
      name: "Feature Flag",
      icon: (
        <FlagIcon
          className="mr-4 flex-shrink-0 h-6 w-6 text-gray-300"
          aria-hidden="true"
        />
      ),
      href: "/flags",
      count: 3,
      current: false,
    },

    // ,
    // {
    //   name: "Settings",
    //   icon: (
    //     <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
    //       <path
    //         className={`fill-current text-white-600 ${
    //           pathname.includes("settings") && "text-gray-500"
    //         }`}
    //         d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
    //       />
    //       <path
    //         className={`fill-current text-gray-400 ${
    //           pathname.includes("settings") && "text-gray-300"
    //         }`}
    //         d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
    //       />
    //       <path
    //         className={`fill-current text-white-600 ${
    //           pathname.includes("settings") && "text-gray-500"
    //         }`}
    //         d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
    //       />
    //       <path
    //         className={`fill-current text-gray-400 ${
    //           pathname.includes("settings") && "text-gray-300"
    //         }`}
    //         d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
    //       />
    //     </svg>
    //   ),
    //   href: "/settings",
    //   count: 0,
    //   current: false,
    // },
  ];

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full overflow-hidden">
        ```
      */}
      <div className="h-full flex">
        {/* Narrow sidebar */}
        <div className="hidden w-28 bg-gray-700 overflow-y-auto md:block">
          <div className="w-full py-6 flex flex-col items-center">
            <div className="flex-shrink-0 flex items-center">
              <img className="h-14 w-auto rounded-full" src={logo} alt="" />
            </div>
            <div className="flex-1 mt-6 w-full px-2 space-y-1">
              {navigationItems.map((item) => (
                <NavLink
                  to={item.href}
                  key={item.name}
                  className={classNames(
                    pathname === item.href
                      ? "bg-gray-800 text-white"
                      : "text-gray-100 hover:bg-gray-800 hover:text-white",
                    "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                  )}
                  aria-current={pathname === item.href ? "page" : undefined}
                >
                  {/* <a
                  key={item.name}
                  href={item.href}
                  
                > */}
                  {item.icon && (
                    <span
                      className={classNames(
                        pathname === item.href
                          ? "text-white"
                          : "text-gray-300 group-hover:text-white",
                        "h-6 w-6"
                      )}
                      aria-hidden="true"
                    >
                      {item.icon}
                    </span>
                  )}

                  <span className="mt-2">{item.name}</span>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
