import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { _auth } from "../modules/_auth";
import { useHistory } from "react-router-dom";
// import { NavLink, useNavigate } from "react-router-dom";
import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import Button from "../components/Button";
import Input from "../components/Input";

function SignupConfirm() {
  // const navigate = useNavigate();
  // const { register } = useAuthStore();
  const history = useHistory();
  const [isValidForSignup, setIsValidForSignup] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeInvalid, SetAccessCodeInvalid] = useState(false);
  const [accessCodeErrorMessage, setAccessCodeErrorMessage] = useState("");
  const [signUpErrorMessage, setSignUpErrorMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageName, setErrorMessageName] = useState("");
  const [errorMessageCompanyName, setErrorMessageCompanyName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    // event.preventDefault();
    history.push("/signin");
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-full">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"></div>
            </div>

            <div className="max-w-md mx-auto px-4 py-8">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                Your account has been created!
              </h1>
              <div className="text-sm">
                Check your email to verify your account and get started!
              </div>

              {/* Form */}

              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                {/* <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"> */}

                {/* </div> */}
                <div className="flex items-center justify-between mt-5">
                  {signUpErrorMessage !== "" && (
                    <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                      {signUpErrorMessage}
                    </div>
                  )}
                  {/* <div className="text-sm">
                      <Link
                        to="#"
                        className="font-extra-small text-indigo-600 hover:text-indigo-500"
                      >
                        {" "}
                        By signing in you accept the t&c and privacy policy
                      </Link>
                    </div> */}
                </div>
                <div className="mt-5">
                  {/* <button
                    // size={ButtonSize.LARGE}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    title="sign in"
                    // variant={ButtonVariant.PRIMARY}
                    onClick={onRegister}
                  /> */}
                  <Button
                    // enabled={!loader}
                    loader={loading}
                    type="submit"
                    className="btn btn-primary"
                    // onClick={onRegister}
                    handleClick={(e) => handleClick(e.target.value)}
                  >
                    Back to Sign In
                  </Button>
                  {/* <Button
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    // variant={ButtonVariant.PRIMARY}
                  >
                    Sign up
                  </Button> */}
                </div>
              </div>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                {/* <div className="text-sm">
                  Have an account?{" "}
                  <Link
                    className="font-medium text-indigo-500 hover:text-indigo-600"
                    to="/signin"
                  >
                    Sign In
                  </Link>
                </div>
                <div className="text-sm">
                  <a
                    className="font-medium text-indigo-500 hover:text-indigo-600"
                    href="https://www.datasecurityu.com"
                  >
                    Need an invitation code?
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        {/* <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div> */}
      </div>
    </main>
  );
}

export default SignupConfirm;
