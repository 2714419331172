import React, { useEffect } from "react";
import LineChart from "../../charts/RiskOverTimeChart";
import Skeleton from "../../components/Skeleton";
// Import utilities
import { tailwindConfig } from "../../utils/Utils";

function RiskOverTimeCard(props) {
  const data = props.data;
  const loading = props.loading;
  const title = props.title;
  const rowspan = props.rowSpan;
  const colSpan = props.colSpan;
  const sorted = props.sorted;
  const [chartLabels, setChartLabels] = React.useState([]);
  const [chartValues, setChartValues] = React.useState([]);
  // const [chartData, setChartData] = React.useState({});
  const labels = props.labels;
  const chartD = props.values;
  console.log("RiskOverTimeCard -->", labels, chartD);
  let mychartData = "";
  ////console.info("Pushing DTAAAAA", data);
  // const [labels, setLabels] = React.useState([]);
  // const [chartD, setChartD] = React.useState([]);
  // const tmpLabels = [];
  // const tmpChartD = [];
  // useEffect(() => {
  //   Object.entries(data).map((k, v) => {
  //     ////console.info("pushing item", "as label", k[1][0], "and total", k[1][1]);
  //     tmpLabels.push(k[1][0]);
  //     tmpChartD.push(k[1][1]);
  //     ////console.info("pushing LABBBBBBBELS", labels);
  //   });
  //   setChartD(tmpChartD);
  //   setLabels(tmpLabels);
  // }, [data]);
  // if (data !== "loading") {
  //   if (sorted == true) {
  //     ////console.info("ROT SORTED", data);
  //     Object.entries(data)
  //       .slice(0, 5)
  //       .map((k, v) => {
  //         ////console.info("pushing item", "as label", k, "and total", v);
  //         labels.push(k[1][0]);
  //         chartD.push(k[1][1]);
  //         ////console.info("pushing LABBBBBBBELS ROT", labels);
  //         ////console.info("pushing LABBBBBBBELS ROT", chartD);
  //       });
  //   } else {
  //     Object.entries(data)
  //       .slice(0, 5)
  //       .map((k, v) => {
  //         ////console.info("pushing item", "as label", k, "and total", v);
  //         labels.push(k[0]);
  //         chartD.push(k[1]);
  //         ////console.info("pushing LABBBBBBBELS ROT", labels);
  //         ////console.info("pushing LABBBBBBBELS ROT", chartD);
  //       });
  //   }
  // }

  if (data !== "loading") {
    // setChartLabels(labels);
    // setChartValues(chartD);
    mychartData = {
      labels: labels,
      datasets: [
        {
          label: "All Customers",
          data: chartD, // [35, 30, 35],
          borderColor: tailwindConfig().theme.colors.purple[400],
          fill: false,
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.purple[400],
        },
      ],
    };
    // setChartData(mychartData);
    // ////console.info("ROT Card", chartData);
  }

  // Object.entries(data).map((k, v) => {
  //   ////console.info("Adding", k, " to labels");
  //   labels.push(new Date(k[0]));
  //   ////console.info("Adding", v, " to chartD");
  //   chartD.push(v);
  // });
  // Object.keys(data).map((item) => {
  //   labels.push(new Date(item));
  //   ////console.info("LABBBBBBBELS", labels);
  // });
  // Object.values(data).map((item) => {
  //   chartD.push(item[1]);
  // });

  ////console.info("Risk over time Labelss", labels);
  ////console.info("Risk over time ChartD", chartD);

  // const chartData = {
  //   // labels: labels,
  //   labels: [
  //     "07-22-2022",
  //     "07-25-2022",
  //     "07-26-2022",
  //     "08-01-2022",
  //     "08-02-2022",
  //     "08-09-2022",
  //   ],

  //   datasets: [
  //     // Indigo line
  //     {
  //       label: "Adoption",
  //       // data: chartD,
  //       data: [6, 7, 1, 8, 5, 1],
  //       // data: [
  //       //   184, 86, 42, 378, 42, 243,
  //       //   // 38, 120, 0, 0, 42, 0, 84, 0, 276, 0, 124,
  //       //   //   42, 124, 88, 88, 215, 156, 88, 124, 64,
  //       // ],

  //       borderColor: tailwindConfig().theme.colors.blue[400],
  //       fill: false,
  //       borderWidth: 2,
  //       tension: 0,
  //       pointRadius: 0,
  //       pointHoverRadius: 3,
  //       pointBackgroundColor: tailwindConfig().theme.colors.blue[400],
  //     },
  //     // Blue line
  //     // {
  //     //   label: "CPRA",
  //     //   data: [
  //     //     184, 86, 42, 378, 42, 243, 38, 120, 0, 0, 42, 0, 84, 0, 276, 0, 124,
  //     //     42, 124, 88, 88, 215, 156, 88, 124, 64,
  //     //   ],
  //     //   borderColor: tailwindConfig().theme.colors.blue[400],
  //     //   fill: false,
  //     //   borderWidth: 2,
  //     //   tension: 0,
  //     //   pointRadius: 0,
  //     //   pointHoverRadius: 3,
  //     //   pointBackgroundColor: tailwindConfig().theme.colors.blue[400],
  //     // },
  //     // Green line
  //     // {
  //     //   label: 'Average',
  //     //   data: [
  //     //     122, 170, 192, 86, 102, 124, 115,
  //     //     115, 56, 104, 0, 72, 208, 186,
  //     //     223, 188, 114, 162, 200, 150, 118,
  //     //     118, 76, 122, 230, 268,
  //     //   ],
  //     //   borderColor: tailwindConfig().theme.colors.green[500],
  //     //   fill: false,
  //     //   borderWidth: 2,
  //     //   tension: 0,
  //     //   pointRadius: 0,
  //     //   pointHoverRadius: 3,
  //     //   pointBackgroundColor: tailwindConfig().theme.colors.green[500],
  //     // },
  //   ],
  // };

  return (
    <div
      className={`flex  flex-col col-span-full p-5 sm:col-span-6 md:col-span-${colSpan}  lg:col-span-${colSpan} xl:col-span-${colSpan}  grow-0 row-span-${rowspan} bg-white shadow-lg rounded-sm border border-gray-200`}
      // className="p-5 col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200"
    >
      <header className=" py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">{props.title}</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      {loading ? (
        <Skeleton type="spinner" color="gray" fill="purple" />
      ) : (
        <LineChart
          data={mychartData}
          width={595}
          height={248}
          showLegend={true}
        />
      )}
    </div>
  );
}

export default RiskOverTimeCard;
