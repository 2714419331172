import React, { useEffect, useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../components/DropdownFilter";
import Datepicker from "../components/Datepicker";
import { Link, useHistory } from "react-router-dom";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";
import DashboardCard08 from "../partials/dashboard/DashboardCard08";
import DashboardCard09 from "../partials/dashboard/DashboardCard09";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import RiskOverTimeCard from "../partials/dashboard/RiskOverTimeCard";
import AnalyticsCard05 from "../partials/dashboard/AnalyticsCard05";
import RiskAssessmentHistoryCard from "../partials/dashboard/RiskAssessmentHistoryCard";
import { _assessment } from "../modules/_assessment";
import { assessments } from "../modules/assessments";
import Stats from "../components/Stats";
import { ChartSquareBarIcon } from "@heroicons/react/solid";
import { utils } from "../modules/_utils";
import { _auth } from "../modules/_auth";
import { audit } from "../modules/_audit";
import { FeatureFlag } from "../components/FeatureFlag";
import AnalyticsCard02 from "../partials/analytics/AnalyticsCard02";
import FintechCard01 from "../partials/dashboard/FintechCard01";
import FintechCard02 from "../partials/dashboard/FintechCard02";
import FintechCard03 from "../partials/dashboard/FintechCard03";
import FintechCard04 from "../partials/dashboard/FintechCard04";
import FintechCard05 from "../partials/dashboard/FintechCard05";
import FintechCard06 from "../partials/dashboard/FintechCard06";
import FintechCard07 from "../partials/dashboard/FintechCard07";
import FintechCard08 from "../partials/dashboard/FintechCard08";
import FintechCard09 from "../partials/dashboard/FintechCard09";
import FintechCard10 from "../partials/dashboard/FintechCard10";
import FintechCard11 from "../partials/dashboard/FintechCard11";
import FintechCard12 from "../partials/dashboard/FintechCard12";
import FintechCard13 from "../partials/dashboard/FintechCard13";
import FintechCard14 from "../partials/dashboard/FintechCard14";
import AnalyticsCard03 from "../partials/analytics/AnalyticsCard03";
import AnalyticsCard04 from "../partials/analytics/AnalyticsCard04";
import AnalyticsCard06 from "../partials/analytics/AnalyticsCard06";
import AnalyticsCard07 from "../partials/analytics/AnalyticsCard07";
import AnalyticsCard08 from "../partials/analytics/AnalyticsCard08";
import AnalyticsCard09 from "../partials/analytics/AnalyticsCard09";
import AnalyticsCard10 from "../partials/analytics/AnalyticsCard10";
import AnalyticsCard11 from "../partials/analytics/AnalyticsCard11";
import FinDashboardCard01 from "../partials/dashboard/FinDashboardCard01";
import { dashboard } from "../modules/_dashboard";
//test change

function Dashboard() {
  const history = useHistory();

  const user = _auth.getUserProfile();
  ////console.info("DASHBOARD USER -->", user);
  const [loading, setLoading] = useState(true);
  const [todaysActiveUsers, setTodaysActiveUsers] = useState("loading");
  const [todaysTotalUsers, setTodaysTotalUsers] = useState("loading");
  const [totalUsersHistory, setTotalUsersHistory] = useState("loading");
  const [todaysTotalCustomers, setTodaysTotalCustomers] = useState("loading");
  const [totalCustomersHistory, setTotalCustomersHistory] = useState("loading");
  const [dailyActiveUsers, setDailyActiveUsers] = useState("loading");
  const [mostActiveUsers, setMostActiveUsers] = useState("loading");

  const data = {};

  useEffect(() => {
    (async () => {
      //active users
      let resp = await dashboard.getTodaysActiveUsers();
      if (resp) {
        console.log("setTodaysActiveUsers", resp?.data[0]?.count);
        setTodaysActiveUsers(resp.data?.length > 0 ? resp.data[0].count : 0);
      }

      let resp4 = await dashboard.getDailyActiveUsers();
      if (resp4) {
        console.log("setDailyActiveUsers", resp4.data);
        setDailyActiveUsers(resp4.data);
      }

      //total users
      let resp2 = await dashboard.getTotalUsers();
      console.info("ORAN2", resp2.data);
      setTodaysTotalUsers(resp2.data);

      let resp1 = await dashboard.getTotalUsersHistory();
      console.info("resp1", resp1.data);
      setTotalUsersHistory(resp1.data);

      //total customers
      let resp3 = await dashboard.getTotalCustomers();
      setTodaysTotalCustomers(resp3.data);

      let resp5 = await dashboard.getTotalCustomersHistory();
      console.info("resp5", resp5.data);
      setTotalCustomersHistory(resp5.data);

      let resp6 = await dashboard.getTopUsers();
      console.info("resp6", resp6.data);
      setMostActiveUsers(resp6.data);

      // let response = await _assessment.getAssessmentHistory();
      // setAssessmentHistory(response);

      // let tempArr = [];
      // Object.entries(assessments.getDateTotals(response)).forEach((date) => {
      //   var d = new Date(date[0]),
      //     month = "" + (d.getMonth() + 1),
      //     day = "" + d.getDate(),
      //     year = d.getFullYear();

      //   if (month.length < 2) month = "0" + month;
      //   if (day.length < 2) day = "0" + day;
      //   let newDate = [month, day, year].join("-");
      //   ////console.info("OTHER NEW DATE -->", newDate);
      //   let total = date[1];
      //   ////console.info("Got getDate New Date", newDate, total);
      //   if (newDate != "Invalid Date" && date[0] != undefined) {
      //     tempArr.push([newDate, total]);
      //   }
      // });

      // ////console.info("Got getDate TEMP ARR", tempArr);
      // setRotCardData(tempArr);
      // ////console.info("Get top assessment takers");
      // setTopAssessmentTakers(assessments.getTotals(response, "taken_by", true));
      // setTopAssessments(
      //   assessments.getTotals(response, "assessment_type", true)
      // );
      // ////console.info("Got top assessment takers");
      // setTodaysHistory(
      //   response.filter((item) => {
      //     return (
      //       item.assessment_date ===
      //       new Date().toLocaleDateString("en-us", {
      //         // weekday: "long",
      //         year: "numeric",
      //         month: "long",
      //         day: "numeric",
      //       })
      //     );
      //   })
      // );
      // const riskArray = [];
      // response.length > 0 &&
      //   response.map((item) => {
      //     item.risk_score != null &&
      //       item.risk_score != "null" &&
      //       riskArray.push(item.risk_score);
      //   });
      // ////console.info("RISK ARRAY", riskArray);
      // const countOfEach = {};
      // for (const num of riskArray) {
      //   countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
      // }
      // setAvgRisk(
      //   Object.keys(countOfEach).length > 0
      //     ? Object.keys(countOfEach).reduce((a, b) =>
      //         countOfEach[a] > countOfEach[b] ? a : b
      //       )
      //     : null
      // );
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    console.log(">resppp");
    _auth.getAuth(history);
  }, []);

  // const [assessmentHistory, setAssessmentHistory] = useState([]);
  // const [rotCardData, setRotCardData] = useState([]);
  // const [topAssessmentTakers, setTopAssessmentTakers] = useState([]);
  // const [topAssessments, setTopAssessments] = useState([]);
  // const [todaysHistory, setTodaysHistory] = useState([]);

  // const todaysHistoryCount = todaysHistory.length;
  ////console.info("TODAYS HISTORY", todaysHistory);
  // const unfinishedToday =
  //   todaysHistory.length > 0 &&
  //   todaysHistory.filter((item) => {
  //     return item.risk_score === "null";
  //   });

  // const datesAndTotals = assessments.getDateTotals(assessmentHistory);

  ////console.info("GETTING ASMT HISTORY FOR DASHBOARD", assessmentHistory);
  // const unFinishedAssessments =
  //   assessmentHistory.length > 0 &&
  //   assessmentHistory.filter((item) => {
  //     return item.risk_score === "null";
  //   });
  ////console.info("UNFINISHED ASSESSMENTS", unFinishedAssessments);
  // const riskArray = [];
  // assessmentHistory.length > 0 &&
  //   assessmentHistory.map((item) => {
  //     item.risk_score != null &&
  //       item.risk_score != "null" &&
  //       riskArray.push(item.risk_score);
  //   });
  ////console.info("RISK ARRAY", riskArray);
  // const countOfEach = {};
  // for (const num of riskArray) {
  //   countOfEach[num] = countOfEach[num] ? countOfEach[num] + 1 : 1;
  // }
  ////console.info(" RISK ARRAY CountofEach", countOfEach);
  ////console.info("COUNTOFECH > 0", Object.keys(countOfEach).length > 0);
  // const [avgRisk, setAvgRisk] = useState(
  //   Object.keys(countOfEach).length > 0
  //     ? Object.keys(countOfEach).reduce((a, b) =>
  //         countOfEach[a] > countOfEach[b] ? a : b
  //       )
  //     : null
  // );
  // setAvgRisk(

  // );
  ////console.info(" RISK ARRAY test", avgRisk);
  // const values = Object.values(countOfEach);
  // ////console.info(" RISK ARRAY VALUES", values);
  // const max = Math.max(...values);
  // console.log("RISK ARRAY MAX", max);
  const charts = [
    {
      component: (
        <FinDashboardCard01
          loading={loading}
          data={loading == true ? "loading" : todaysTotalCustomers.current}
          change={todaysTotalCustomers.diff}
          changePercent={
            todaysTotalCustomers.percentage
              ? todaysTotalCustomers.percentage.toFixed(2)
              : todaysTotalCustomers.percentage
          }
          currency=""
          title="Total Customers"
          labels={totalCustomersHistory.labels}
          values={totalCustomersHistory.values}
        />
      ),
    },
    {
      component: (
        <FinDashboardCard01
          loading={loading}
          data={todaysTotalUsers.current}
          change={todaysTotalUsers.diff}
          changePercent={
            todaysTotalUsers.percentage
              ? todaysTotalUsers.percentage.toFixed(2)
              : todaysTotalUsers.percentage
          }
          currency=""
          title="Total Users"
          labels={totalUsersHistory.labels}
          values={totalUsersHistory.values}
        />
      ),
    },
    {
      component: (
        <FinDashboardCard01
          loading={loading}
          data={loading == true ? null : todaysActiveUsers}
          change={dailyActiveUsers.diff}
          changePercent={dailyActiveUsers.percentage}
          currency=""
          title="Today's Active Users"
          labels={loading ? "loading" : dailyActiveUsers.labels}
          values={loading ? "loading" : dailyActiveUsers.values}
        />
      ),
    },
    {
      component: (
        <FinDashboardCard01
          loading={loading}
          data={2000}
          change={0}
          changePercent={0}
          currency="$"
          title="Current MRR"
          labels={loading ? "loading" : []}
          values={loading ? "loading" : []}
        />
      ),
    },
    // {
    //   title: "Today's Active Users",
    //   icon: <ChartSquareBarIcon />,
    //   component: (
    //     <DashboardCard01
    //       loading={loading}
    //       data={loading == true ? "loading" : avgRisk}
    //       title="Today's Active Users"
    //       scores={countOfEach}
    //     />
    //   ),
    // },
    // {
    //   title: "Top Assessment",
    //   icon: <ChartSquareBarIcon />,
    //   component: (
    //     <DashboardCard01
    //       loading={loading}
    //       data={
    //         loading == true
    //           ? "loading"
    //           : topAssessments.length > 0
    //           ? topAssessments.slice(0, 1)[0][0].toUpperCase()
    //           : []
    //       }
    //       title="Top Assessment"
    //       total={topAssessments.length > 0 ? topAssessments[0][1] : "loading"}
    //     />
    //   ),
    // },
  ];
  return (
    // <div className="flex h-screen overflow-hidden">

    //   {/* Sidebar */}
    //   <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

    //   {/* Content area */}
    //   <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

    //     {/*  Site header */}
    //     <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

    //     <main>
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Welcome banner */}
        <div className="mb-0 sm:mb-0 inline-flex ">
          <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
            Dashboard
          </h1>
        </div>
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* Filter button */}
          {/* <FilterButton align="right" /> */}
          {/* Datepicker built with flatpickr */}
          {/* <Datepicker align="right" /> */}
          {/* Take Assessment */}
          <FeatureFlag type="flag">
            {/* <Link to="../assessmentselect">
              <button
                onClick={() => {
                  audit.logAction("Launch Assessment");
                }}
                className="btn bg-lime-500 hover:bg-lime-600 text-white"
              >
                <svg
                  className="w-4 h-4 fill-current opacity-50 shrink-0"
                  viewBox="0 0 16 16"
                >
                  <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="hidden xs:block ml-2">Take Assessment</span>
              </button>
            </Link> */}
          </FeatureFlag>
          {/* Add view button */}

          {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add view</span>
          </button> */}
        </div>
      </div>
      {/* <WelcomeBanner userName={"John"} /> */}
      {/* Dashboard actions */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Avatars */}
        {/* The below is only needed to keep the assmt button on right when Avatars are not present */}
        <div className="flex flex-wrap justify-center sm:justify-start mb-8 sm:mb-0 -space-x-3 -ml-px">
          {" "}
        </div>
        {/* {/* <DashboardAvatars />{" "}  */}
        {/* // Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* Filter button */}
          {/* <FilterButton align="right" /> */}
          {/* Datepicker built with flatpickr */}
          {/* <Datepicker align="right" /> */}
          {/* Take Assessment */}
          {/*Old Assessment Button */}
          {/* Add view button */}

          {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add view</span>
          </button> */}
        </div>
      </div>
      {/* Cards */}

      {/* <Stats /> */}

      <div className="grid grid-cols-12 gap-6">
        {charts.map((chart, index) => {
          {
            return chart.component;
          }
        })}
        {/* <AnalyticsCard02
          title={"Active Customers"}
          total={100}
          data={{}}
          chartData={{}}
          labels={totalUsersHistory.labels}
          values={totalUsersHistory.values}
        /> */}
        <AnalyticsCard05
          loading={loading}
          data={mostActiveUsers.data && mostActiveUsers.data.slice(0, 10)}
          labels={mostActiveUsers.labels}
          values={mostActiveUsers.values}
          title={"Most Active Users"}
          colSpan={4}
          rowSpan={2}
          uppercase={false}
        />

        <RiskOverTimeCard
          loading={loading}
          data={totalCustomersHistory}
          title={"Adoption over time"}
          sorted={true}
          labels={totalCustomersHistory.labels}
          values={totalCustomersHistory.values}
          colSpan={8}
          rowSpan={2}
        />
        {/* <FintechCard01 />
        <FintechCard02 />
        <FintechCard03 />
        <FintechCard04 />
        <FintechCard05 />
        <FintechCard06 />
        <FintechCard07 />
        <FintechCard08 />
        <FintechCard09 />
        <FintechCard10 />
        <FintechCard11 />
        <FintechCard12 />
        <FintechCard13 />
        <FintechCard14 />
        <AnalyticsCard03 />
        <AnalyticsCard04 />

        <AnalyticsCard06 />
        <AnalyticsCard07 />
        <AnalyticsCard08 />
        <AnalyticsCard09 />
        <AnalyticsCard10 />
        <AnalyticsCard11 /> */}

        {/* Line chart (Acme Plus) */}
        {/* <DashboardCard01 /> */}
        {/* Line chart (Acme Advanced) */}
        {/* <DashboardCard02 /> */}
        {/* Line chart (Acme Professional) */}
        {/* <DashboardCard03 /> */}
        {/* Doughnut chart (Top Countries) */}
        {/* Table (Top Channels) */}
        {/* <RiskAssessmentHistoryCard /> */}
        {/* Line chart (Sales Over Time) */}
        {/* Stacked bar chart (Sales VS Refunds) */}
        {/* Card (Recent Activity) */}
        {/* <AnalyticsCard05
          loading={loading}
          data={
            topAssessmentTakers.length > 0
              ? topAssessmentTakers.slice(0, 10)
              : []
          }
          title={"Top Assessment Takers"}
          colSpan={4}
          rowSpan={2}
          uppercase={false}
        />
        <AnalyticsCard05
          loading={loading}
          data={
            topAssessments.length > 0 ? topAssessments.slice(0, 10) : "loading"
          }
          title={"Top Assessments"}
          colSpan={4}
          rowSpan={2}
          uppercase={true}
        />
        <DashboardCard06
          loading={loading}
          data={topAssessmentTakers.length > 0 ? topAssessmentTakers : []}
          title={"Assessments"}
          sorted={true}
          colSpan={4}
          rowSpan={2}
        />
        <RiskAssessmentHistoryCard
          loading={loading}
          data={
            assessmentHistory.length > 0
              ? assessmentHistory.slice(0).reverse()
              : []
          }
        />
        <DashboardCard10
          loading={loading}
          data={assessmentHistory.length > 0 ? assessmentHistory : []}
        /> */}

        {/* Card (Income/Expenses) */}
        {/* <DashboardCard11 /> */}
        {/* Bar chart (Direct vs Indirect) */}
        {/* <DashboardCard04 /> */}
        {/* Line chart (Real Time Value) */}
        {/* <DashboardCard05 />
        <DashboardCard09 />
        <RiskOverTimeCard />
        <DashboardCard06 /> */}
      </div>
    </div>
    //     </main>

    //   </div>

    // </div>
  );
}

export default Dashboard;
