// import PROFILE from "../data_sources/fake.js";

import { utils } from "./_utils";
import { postRequest, getRequest, signupPostRequest } from "./ApiRequest";
import setAuthToken from "../config/setAuthToken";
import jwt_decode from "jwt-decode";

export const _auth = {
  // async signup(u, p, f, c, a, successCallback, errorCallback) {
  //   let request_type = "signup";
  //   // try {
  //   let bodyData = {
  //     username: u,
  //     password: p,
  //     fullname: f,
  //     forceSignUp: true,

  //     company: c,
  //     access_code: a,
  //   };

  //   await post(SIGN_UP, bodyData, successCallback, errorCallback).then(
  //     (response) => {
  //       console.log("RESPTEST DATA", response.data);
  //       successCallback(response.data);
  //     }
  //   );
  // },

  async signup(
    email,
    password,
    fullName,
    companyName,
    accessCode,
    successCallback,
    errorCallback
  ) {
    try {
      let userData = {
        email: email,
        password: password,
        name: fullName,
        company: companyName,
        role: "Admin",
      };
      this.loader = true;
      //console.info(userData);
      await signupPostRequest(
        "new-customer-registration",
        userData,
        successCallback,
        errorCallback
      )
        .then((response) => {
          console.log("RESPTEST DATA", response.data);
          successCallback(response.data);
        })
        .catch((error) => {
          errorCallback(error.response.data);
          // return error.response.data;
        });

      // let response = await postRequest("create-admin-tenant", userData);

      // if (response && response.data.status === 200) {
      //   console.log("Sending Back Response", response);
      //   this.loader = false;
      //   return true;
      // } else {
      //   console.log("There was an error creating the user");
      //   return false;
      // }
    } catch (e) {
      return false;
    }
  },
  async getAuth(history) {
    try {
      let token = localStorage.getItem("jwtToken");
      if (!token) {
        history.push({
          pathname: "/",
        });
      } else {
        setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
        let response = await getRequest("get-auth");
        console.log(response, ">resppp");

        if (response && response.response && response.response.status === 401) {
          console.log("runned >");
          localStorage.removeItem("jwtToken");
          history.push({
            pathname: "/",
          });
          localStorage.removeItem("profile");
        } else {
          return true;
        }
      }
    } catch (e) {
      console.log(e, ">resppp");
    }
  },
  async login(u, p) {
    try {
      let userData = {
        email: u,
        password: p,
      };
      this.loader = true;
      // //console.info(userData);
      let response = await postRequest("login", userData);
      // console.log("Auth Response", response);
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === 200
      ) {
        const { token } = response.data.data;
        // Set token to ls
        utils.setLocalStorage("jwtToken", token);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);
        this.loader = false;
        return [true, decoded];
      } else if (
        response &&
        response.response.status &&
        response.response.status === 400
      ) {
        //console.info("Returning disabled login");
        return [false, response.response.data.errorMessage];
      } else {
        console.log("Returning failed login");
        return [false, "Username or Password is incorrect"];
      }
    } catch (e) {
      console.log("Returning error", e);
      return [false, e];
    }
  },

  async forgotPassword(u, history) {
    try {
      let obj = {
        email: u,
      };
      this.loader = true;
      let response = await postRequest("forgot-password", obj);
      console.log(response.data, "response check with");
      if (response) {
        localStorage.setItem("email", u);
        // Set token to ls
        console.log("Im running");
        this.loader = false;
        history.push("/reset-password");
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },

  async resetPassword(otp, password, confirmpassword, history) {
    try {
      let email = await localStorage.getItem("email");
      this.loader = true;
      let data = {
        otp: otp,
        email: email,
        password: password,
        confirmpassword: confirmpassword,
      };
      let response = await postRequest("reset-password", data);
      console.log(response, "response..");
      if (response) {
        this.loader = false;
        // Set token to ls
        history.push("/");
        return true;
      } else {
        return false;
      }
    } catch (e) {}
  },
  async resetPasswordEmail(id, token, password, confirmpassword, history) {
    try {
      let email = await localStorage.getItem("email");
      this.loader = true;
      let data = {
        id: id,
        token: token,
        email: email,
        password: password,
        confirmpassword: confirmpassword,
      };
      let response = await postRequest("reset-new-user-password", data);
      console.log(response, "response..");
      if (response && response.data.status === 200) {
        // Set token to ls
        this.loader = false;
        history.push({
          path: "/",
        });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },

  async logout(history) {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("profile");
    // history.push({
    //   pathname: "/"
    // });
  },
  loader: false,
  setUserProfile(profile) {
    utils.setLocalStorage("profile", profile);
    return true;
  },
  getUserProfile(u) {
    let profile = utils.getLocalStorage("profile");
    return profile;
  },
  getTenantProfile(tenant) {
    return true;
  },
  getAccessCode() {
    return "111";
  },

  async MFA(otp, history) {
    try {
      this.loader = true;
      let data = {
        otp,
      };
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("mfa-otp", data);
      if (response && response.data.status === 200) {
        // Set token to ls
        history.push({
          path: "/",
        });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  },

  async signup2(email, password, fullName, companyName, accessCode) {
    try {
      let userData = {
        email: email,
        password: password,
        name: fullName,
        company: companyName,
        role: "Admin",
      };
      this.loader = true;
      // //console.info(userData);
      let response = await postRequest("new-customer-registration", userData);

      if (response && response.response && response.response.status !== 200) {
        return response.response.data;
      } else {
        return response.data;
      }

      // return response;
      // //console.info("Received response", response);
      // if (response && response.response) {
      //   //console.info("This is an error message", response.response.data);
      //   return response.response;
      // } else {
      //   console.log("Sending Back Response", response);
      //   this.loader = false;
      //   return response;
      // }
    } catch (error) {
      console.log(error, "error");
      return error.data;
    }
  },
  async addUser(email, password, fullName, role) {
    try {
      let userData = {
        email: email,
        password: password,
        name: fullName,
        role: role,
      };
      this.loader = true;
      // //console.info(userData);
      let response = await postRequest("new-customer-registration", userData);

      if (response && response.response && response.response.status !== 200) {
        return response.response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error, "error");
      return error.data;
    }
  },
  async getAllUsers(tenantId) {
    try {
      let response = await getRequest("get-all-users", {
        tenantId: tenantId,
      });
      return response;
    } catch (e) {
      return false;
    }
  },

  checkNaughtyList(email) {
    //console.info("Checking naughty list for", email);
    let domain = email.split("@")[1];
    //console.info("Checking naughty list for", domain);
    let domains = utils.list();
    if (domains.indexOf(domain) != -1) {
      // //console.info("Found domain in list", domain);
      return true;
    } else {
      // //console.info("Did not find domain in list", domain);
      return false;
    }
  },
};
