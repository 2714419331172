import setAuthToken from "../config/setAuthToken";

const { postRequest, getRequest } = require("./ApiRequest");

export const _user = {
  getUserPanelTabs() {
    const userPanelTabs = [
      { name: "All Users", href: "#", current: true },
      { name: "Add Users", href: "#", current: false },
    ];
    return userPanelTabs;
  },
  async bulkUserUpdate(csv, id) {
    try {
      console.log(csv, "csv");
      const data = new FormData();
      data.append("file", csv);
      data.append("tenantId", id);
      let response = await postRequest("create-bulk-user", data);
      if (response.data) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  },
  async addSingleUser(data) {
    try {
      let token = localStorage.getItem("jwtToken");
      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("new-single-user", data);
      if (response.status === 200) {
        //console.info("Response Returned", response);
        return response;
      } else {
        //console.info("Response Returned", response);
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  },
  async createOnBoard(data) {
    try {
      let response = await postRequest("/on-board");
      if (response.data) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getAllUser(page) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await getRequest(`get-all-users`, {
        params: {
          page: page,
        },
      });
      //debugger;
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async disableUser(id) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("disable-user", {
        user_id: id,
      });
      // debugger;
      if (response.data) {
        const allusers = this.getAllUser();
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  async enableUser(id) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("enable-user", {
        user_id: id,
      });
      // debugger;
      if (response.data) {
        const allusers = this.getAllUser();
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
  canBeReinvited(dt) {
    let date = new Date(dt);
    let today = new Date();
    let diff = Math.abs(today - date);
    let diffHours = Math.ceil(diff / (1000 * 60 * 60));
    //console.info("diff", diffHours);
    if (diffHours > 24) {
      return true;
    } else {
      return false;
    }
  },
  async reinviteUser(id) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("resend-invite", {
        user_id: id,
      });
      //debugger;
      if (response) {
        return true;
      } else {
        return response;
      }
      // if (response.data) {
      //   return;
      // } else {
      //   return [];
      // }
    } catch (e) {
      return e;
    }
  },
  async deleteUserFromTenant(id) {
    try {
      let token = localStorage.getItem("jwtToken");

      setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
      let response = await postRequest("delete-user-tenant", {
        user_id: id,
      });
      // debugger;
      if (response.data) {
        const allusers = this.getAllUser();
        if (allusers) {
          return allusers;
        } else {
          return [];
        }
        // return true;
      } else {
        return [];
      }
    } catch (e) {
      return e;
    }
  },
};
