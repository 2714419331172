import React, { useState, useEffect } from "react";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import { useHistory } from "react-router-dom";
import { _auth } from "../modules/_auth";
import NarrowSidebar from "../components/NarrowSidebar";
import { _navigation } from "../modules/_navigation";

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigationItems = _navigation.getHeaderLinks();
  let history = useHistory();
  useEffect(() => {
    console.log(">resppp");
    _auth.getAuth(history);
  }, []);
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
      <NarrowSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigationItems={navigationItems}
      />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          // className="sticky top-0 z-20 absolute"
          style={{}}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <main>{children}</main>
      </div>
    </div>
  );
};

export default Layout;
