import { postRequest } from "./ApiRequest";
import { _auth } from "./_auth";
import setAuthToken from "../config/setAuthToken";

export const features = {
  async getFeatureFlags(featureName) {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let body = {};
    if (featureName) {
      let FeatureName = featureName;
    }
    body.FeatureName = featureName;
    let response = await postRequest("/featureflags/get", body);
    console.log("response", response.data.data.data);
    return response.data.data.data;
  },
  async updateFeatureFlag(data, feature, value) {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let body = data;
    console.log("body", body);
    let response = await postRequest("/featureflags/update", body);
    if (response) {
      console.log("update response", response);
    }
    return response;
  },
  create(data) {
    let token = localStorage.getItem("jwtToken");
    setAuthToken(token.replace(/"([^"]+(?="))"/g, "$1"));
    let body = data;
    console.log("body", body);
    let response = postRequest("/featureflags/create", body);
    if (response) {
      console.log("/featureflags/create", response);
    }
    return response;
  },
  deleteFeatureFlag(data) {
    return postRequest("/featureflags/delete", data);
  },
};
