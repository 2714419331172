import React, { useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/solid";
import OnboardingImage from "../images/onboarding-image.jpg";
import OnboardingDecoration from "../images/auth-decoration.png";
import { _onboarding_options } from "../modules/_onboarding_options";
import LandingIntro from "../components/LandingIntro";
import ArrowSteps from "../components/ArrowSteps";
import StackedList from "../components/StackedList";
import { current } from "tailwindcss/colors";
import { _assessment } from "../modules/_assessment";
import { _auth } from "../modules/_auth";
const steps = _onboarding_options.getOnboardingSteps();
const roles = _onboarding_options.getEmployeeRoles();
const departments = _onboarding_options.getEmployeeDepartments();
const companySize = _onboarding_options.getCompanySize();
const companyLocation = _onboarding_options.getCompanyLocation();
const options = [];
const assessment_types = _assessment.getAssessmentNames();
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AssessmentSelect() {
  const history = useHistory();
  useEffect(() => {
    console.log(">resppp");
    _auth.getAuth(history);
  }, []);
  const goToStartAssessment = (a) => {
    let type = a.key;
    let name = a.name;
    //console.info("goToStartAssessment", type);
    let state = null;
    if (type != "pre") {
      state = {
        isPreassessment: false,
        assessment_type: type,
        assessment_name: name,
        resume: false,
        // new_history_item: _assessment.getJsonTemplate(),
      };
    } else {
      state = {
        isPreassessment: true,
        assessment_type: "pre",
        assessment_name: a.name,
        resume: false,
      };
    }

    return history.push({
      pathname: "/assessment",
      state: state,
    });
  };

  const [selectedRole, setSelectedRole] = useState(roles[0]);
  const [selectedDepartment, setSelectedDepartment] = useState(departments[0]);
  const [selectedSize, setSelectedSize] = useState(companySize[0]);
  const [selectedLocation, setSelectedLocation] = useState(companyLocation[0]);
  const [currentStep, setStep] = useState(1);
  return (
    // <StackedList title="Other actions" options={options} showButtons={false} />
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto bg-white">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
            Choose assessment
          </h1>
        </div>
      </div>

      {/* Content */}
      <div className="max-w-md mx-auto">
        <div className="min-h-screen h-full flex flex-col after:flex-1">
          <div className="m-1.5">
            {/* Modal content */}
            <div className="px-5 pt-4 pb-1">
              <div className="text-sm">
                <div className="mb-4">
                  Select an assessment type to continue:
                </div>
                {/* Options */}
                <ul className="space-y-2 mb-4">
                  {assessment_types.map((a) => {
                    return (
                      a.status != "disabled" && (
                        <li key={a.key}>
                          <button
                            disabled={
                              a.status !== "production" && a.status !== "Beta"
                            }
                            onClick={() => goToStartAssessment(a)}
                            className="w-full h-full text-left py-3 px-4 rounded bg-white border border-gray-200 hover:border-gray-300 shadow-sm duration-150 ease-in-out"
                          >
                            <div className="flex items-center">
                              {/* <div className="w-4 h-4 border-2 border-gray-300 rounded-full mr-3"></div> */}
                              <div className="grow">
                                <div className="flex flex-wrap items-center justify-between mb-0.5">
                                  <span className="font-semibold text-gray-800">
                                    {a.name}
                                    {a.status != "production" && (
                                      <span className="normal-case text-xs italic text-indigo-500 align-top ml-1">
                                        {a.status}
                                      </span>
                                    )}
                                  </span>
                                  {/* <span>
                                <span className="font-medium text-green-600">
                                  $59.00
                                </span>
                                /mo
                              </span> */}
                                </div>
                                <div className="text-xs">{a.description}</div>
                              </div>
                            </div>
                          </button>
                        </li>
                      )
                    );
                  })}
                </ul>
                {/* <div className="text-xs text-gray-500">
                  After selecting your assessment, click Next to begin.
                </div> */}
              </div>
            </div>
            {/* Modal footer */}
            <div className="px-5 py-8">
              <div className="flex flex-wrap justify-end space-x-2">
                <Link to="/assessments">
                  <button
                    className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    Cancel
                  </button>
                </Link>
                {/*                 
                <button
                  className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                  onClick={() => goToStartAssessment()}
                >
                  Next
                </button> */}
                {/* </Link> */}
              </div>
            </div>

            {/* End */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentSelect;
