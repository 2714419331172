/*
  To show a feature based on criteria, wrap the feature's button in this component.
    The component will check the feature's flag and return the button if the flag is true.

    Example:
        <Feature type="product" productId={productID} featureName="Settings">
            <button>Feature</button>
        </Feature>

    The type can be "flag", "test", or "subscription", and others will be added in the future.
    The "flag" type will check the feature's flag and return the button if the flag is true.
    The "test" type will check if the user is a DSU user and return the button if true.
    The "subscription" type will check if the user has a subscription to the product and return the button if true.

  */

/* Should we auto-show all features in Beta? */

import React from "react";
import { features } from "../modules/features";
import { _auth } from "../modules/_auth";

export function FeatureFlag(props) {
  const { type } = props;
  const { productId } = props;
  const { featureName } = props;
  const { children } = props;

  if (type === "flag") {
    return <>{children}</>;
  } else if (type === "test") {
    return null;
  }
}
