import React, { useState } from 'react';

import ProfileSidebar from './partials/ProfileSidebar';
import ProfileBody from './partials/ProfileBody';

function Profile() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [profileSidebarOpen, setProfileSidebarOpen] = useState(false);

  return (
   
          <div className="relative flex">

            {/* Profile sidebar */}
            <ProfileSidebar profileSidebarOpen={profileSidebarOpen} setProfileSidebarOpen={setProfileSidebarOpen} />

            {/* Profile body */}
            <ProfileBody profileSidebarOpen={profileSidebarOpen} setProfileSidebarOpen={setProfileSidebarOpen} />

          </div>
       
  );
}

export default Profile;