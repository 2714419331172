export const _navigation = {
  getHeaderLinks() {
    const navigation = [
      { name: "Home", href: "/home", current: true, icon: "home", show: true },

      {
        name: "Employees",
        href: "/employees",
        current: false,
        icon: "employees",
        show: false,
        status: "prod",
      },
      {
        name: "Learning",
        href: "/learninghub",
        current: false,
        icon: "learning",
        show: false,
        status: "prod",
      },
      {
        name: "Assessments",
        href: "/assessmenthub",
        current: false,
        icon: "assessment",
        show: false,
        status: "prod",
      },

      {
        name: "Assessments",
        href: "/assessments",
        current: false,
        icon: "assessment",
        show: true,
        status: "prod",
      },

      {
        name: "Marketplace",
        href: "/marketplace",
        current: false,
        icon: "marketplace",
        show: true,
        status: "prod",
      },
      {
        name: "Community",
        href: "/community",
        current: false,
        icon: "community",
        show: false,
        status: "coming soon",
      },
      {
        name: "Education",
        href: "/analytics",
        current: false,
        icon: "learning",
        show: false,
        status: "coming soon",
      },
      {
        name: "Get Help",
        href: "/contact",
        current: false,
        icon: "support",
        show: true,
        status: "",
      },
    ];
    return navigation;
  },
};
