import { getRequest } from "./ApiRequest";
import { _auth } from "./_auth";

export const audit = {
  isProd() {
    return process.env.NODE_ENV === "production";
  },

  profile() {
    return _auth.getUserProfile();
  },

  logAction(action, details) {
    let profile = this.profile();
    let data = {};
    //console.info("AUDIT", action, details);

    data.action = action;
    data.role = profile.role;
    data.email = profile.email;
    data.name = profile.name;
    data.user_id = profile.user_id;
    data.tenantId = profile.tenantId;
    data.dateTime = new Date().toISOString();
    data.details = details;

    if (audit.isProd()) {
      //send to server
      console.log("Call  API", action, data);
    } else {
      //log to console
      console.log("Console Log ", action, data);
    }
  },
  getAuditLog() {
    let body = {};
    let data = getRequest("get-history", body);
    if (data) {
      console.log("Data.data", data);
    }

    return data;
  },
};
