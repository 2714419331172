import { postRequest, getRequest, signupPostRequest } from "./ApiRequest";

export const dashboard = {
  getTotalCustomers() {
    return 1000;
  },
  getTotalCustomerHistory() {
    return [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  },
  getTotalCustomers() {
    let data = getRequest("get-total-customers");
    if (data) {
      return data;
    }
  },
  getTotalCustomersHistory() {
    let data = getRequest("get-total-customers-history");
    if (data) {
      return data;
    }
  },
  getTotalUsers() {
    let data = getRequest("get-total-users");
    if (data) {
      return data;
    }
  },
  getTotalUsersHistory() {
    let data = getRequest("get-total-users-history");
    if (data) {
      return data;
    }
  },
  async getTodaysActiveUsers() {
    let data = await getRequest("get-todays-active-users");
    if (data) {
      console.log("getTodaysActiveUsers", data);
      return data;
    }
  },
  getDailyActiveUsers() {
    let data = getRequest("get-daily-active-users");
    if (data) {
      return data;
    }
  },
  getYeseterdaysActiveUsers() {
    let data = getRequest("get-yeseterdays-active-users");
    if (data) {
      return data;
    }
  },
  getActiveUserHistory() {
    return [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  },
  getTopUsers() {
    let data = getRequest("get-top-users");
    if (data) {
      return data;
    }
  },
};
